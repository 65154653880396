import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

export const getSopList = async(params)=>{
  return await request.post(`${SERVER.SOP_SERVISE}/v1/task/list`, params).then(thenResolve)
}

export const taskComplete = async(params)=>{
  return await request.post(`${SERVER.SOP_SERVISE}/v1/task/personal/finish`, params).then(thenResolve)
}

export const taskStatistic = async(params)=>{
  return await request.post(`${SERVER.SOP_SERVISE}/v1/task/personal/statistic`, params).then(thenResolve)
}