<template>
    <div class="sop-task">
        <VanTabs
            :queryType="queryType"
            :tabList="tabList"
            @change="changeTab"
            >
        </VanTabs>
        <List ref="list" :fetchApi="fetchApi">
            <template v-slot:default="{ item, index }">
                <SopItem :item="item" :isShowBtn="[1,3].includes(queryType)" @finish="onComplete"/>
            </template>
        </List>
    </div>
</template>
<script>
import VanTabs from '@/components/VanTabs'
import SopItem from './components/sop-item.vue'
import loading from '@/utils/loading'
import List from '@/components/baseList/list.vue'
import { getSopList,taskComplete,taskStatistic } from '@/services/sop.js'
import qs from 'qs'
import dayjs from 'dayjs'
export default {
  name: 'SopTask',
  components: { VanTabs,SopItem,List },
  data(){
    return {
      queryType: 1,
      tabList: [
        {
          text: '未完成',
          value: 1,
          total: 0,
          key: 'unComplete',
        },
        {
          text: '已完成', 
          value: 2,
          total: 0,
          key: 'finish',
        },
        {
          text: '已过期', 
          value: 3,
          total: 0,
          key: 'expired',
        }
      ],
      taskId: '',
      isSearchAll: false
    }
  },
  created(){
    // url上带了id则默认展示对应的那条数据，点完成 or 切换 tab 时需要将参数清除
    const { id } = this.$route.query
    if (id){
      this.taskId = id
      this.isSearchAll = true
    }
    this.getCount()
  },
  
  methods: {
    // 获取统计数据
    async getCount(){
      const res = await taskStatistic({})
      this.tabList.forEach(e=>{
        e.total = res[e.key]
      })
    },
    changeTab(val){
      this.queryType = val
      this.taskId = ''
      this.isSearchAll = false
      this.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      loading.showLoading()
      const { currentPage,pageSize }= obj
      const params = {
        pageIndex: currentPage,
        pageSize,
      }
      if (!this.isSearchAll) params.taskStatus = this.queryType
      if (this.taskId){
        params.taskId = this.taskId
      }
      return getSopList(params).then((res) => {
        loading.hideLoading()
        // 按照返回的list定位tab展示哪一个,并且对应的tab统计数据由list数量决定
        if (this.isSearchAll&&res.dataList.length){
          setTimeout(() => {
            this.queryType = res.dataList[0].taskStatus
            const idx = this.tabList.findIndex(item=>item.value===this.queryType)
            this.$set(this.tabList[idx],'total',res.dataList.length)
          }, 300)
        }
        return res
      }).catch(error=>{
        loading.hideLoading()
      })
    },
    clearUrlId(){
      this.taskId = ''
      this.isSearchAll = false
      const query = this.$route.query
      query.id = ''
      location.href = `${location.href.split('?')[0]}?${qs.stringify(query)}`
    },
    // 任务完成
    async onComplete(taskId,scheduleId){
      const params = { taskId,scheduleId }
      const res = await taskComplete(params)
      this.$toast(this.$t('操作成功'))
      setTimeout(() => {
        if (this.taskId){
          // 去除taskId 和 url上的id
          this.clearUrlId()
        } else {
          // this.onRefresh()
          this.getCount()
          // 刷新数据状态，但是不改变滚动条位置
          this.changeList(taskId,scheduleId)
        }
      }, 500)
    },
    changeList(taskId,scheduleId){
      // 单次任务，直接将该条数据清除，周期任务则修改状态
      const listData = this.$refs.list.list
      const idx = listData.findIndex(e=>e.id===taskId)
      if (idx<0){
        this.$refs.list.onRefresh()
        return
      }
      const { taskType,taskScheduleVoList,secondCompletedNum,secondUnCompletedNum } = listData[idx]
      if (taskType===1){
        this.$refs.list.list.splice(idx,1)
      } else {
        if (secondUnCompletedNum&&secondUnCompletedNum-1===0){
          // 直接清除数据
          this.$refs.list.list.splice(idx,1)
        } else {
          const subTaskIdx = taskScheduleVoList.findIndex(e=>e.id===scheduleId)
          listData[idx].secondCompletedNum = secondCompletedNum + 1
          listData[idx].secondUnCompletedNum = secondUnCompletedNum - 1
          listData[idx].taskScheduleVoList[subTaskIdx].scheduleStatus = 3
          listData[idx].taskScheduleVoList[subTaskIdx].scheduleStatusStr = '已完成'
          listData[idx].taskScheduleVoList[subTaskIdx].finishTimeStr = dayjs().format('YYYY-MM-DD HH:mm:ss')
        }
      }
    },
    // 刷新线索数据
    onRefresh() {
      this.getCount()
      this.$refs.list.onRefresh()
    },
  }
}
</script>