<template>
    <div class="sop-item">
      <div class="head">
          <div class="sop-title"> 
            <span class="label">任务名称：</span>
            <span class="value">{{ item.taskName }}</span>
          </div>
          <van-button v-if="operateBtn" round plain class="complete-btn" size="mini" color="#eed484" @click="onFinish(item.id,item.taskScheduleVoList[0]?.id)">完成</van-button>
      </div>
      <div class="content">
          <div v-for="e in taskContent" :key="e.label" class="row">
              <span class="label van-ellipsis">{{ e.label }}：</span>
              <span class="value">
                <span v-if="item.taskType===2&&e.key==='taskConfigStr'">{{ taskConfigStr }}</span>
                <span v-else>{{ item[e.key] }}</span>
              </span>
          </div>
      </div>
      <!-- 周期任务才展示 -->
      <div v-if="item.taskType===2" class="footer">
        <div style="flex:1">
          <span class="completed">已完成（{{ item.secondCompletedNum }}）</span>
          <span class="completed">未完成（{{ item.secondUnCompletedNum }}）</span>
        </div>
        <div @click="isExpand=!isExpand">
          <span>详情</span>
          <van-icon v-if="!isExpand" name="arrow-down" />
          <van-icon v-if="isExpand" name="arrow-up"/>
        </div>
      </div>
      <div v-if="isExpand" class="sub-task">
        <div v-for="(subItem,idx) in item.taskScheduleVoList" :key="subItem.id" class="sub-item">
          <div class="sub-head">
            <div :class="[idx===0?'current-task':'']">
              <span>子任务{{ idx+1 }}</span>
              <span v-if="idx===0">（当前任务）</span>
            </div>
            <van-button v-if="isCurrentTask(subItem)&&item.executorId===userInfo.id" round plain size="mini" color="#eed484" class="complete-btn" @click="onFinish(item.id,subItem.id)">完成</van-button>
          </div>
          <div v-for="field in subFieldList" :key="field.key">
            <span>{{ field.label }}：</span>
            <span>{{ subItem[field.key] }}</span>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  name:'SopItem',
  props: {
    item: {
      type:Object,
      default: ()=>({})
    },
    isShowBtn: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      // taskContent: [
      //   { label:'任务内容',key: 'taskDetail' },
      //   { label:'任务执行人',key: 'executorName' },
      //   { label:'要求完成时间',key: 'endTimeStr' },
      // ],
      isExpand: false, // 是否展开
      subFieldList: [
        { label:'任务要求时间',key: 'endTimeStr' },
        { label:'完成状态',key: 'scheduleStatusStr' },
        { label:'实际完成时间',key: 'finishTimeStr' },
      ]
    }
  },
  
  computed:{
    ...mapGetters(['userInfo']),
    operateBtn() {
      const { id } = this.userInfo
      const { executorId,taskType,endTimeStr } = this.item
      return executorId === id && this.isShowBtn && taskType!==2 && !dayjs().isBefore(dayjs(endTimeStr),'D')
    },
    taskContent(){
      let list = [
        { label:'任务内容',key: 'taskDetail' },
        { label:'任务执行人',key: 'executorName' },
      ]
      if (this.item.taskType===2){
        list = list.concat([
          { label:'要求完成时间',key: 'taskConfigStr' },
          { label:'任务截止时间',key: 'endTimeStr' },
        ])
      } else {
        list = list.concat([
          { label:'要求完成时间',key: 'endTimeStr' },
        ])
      }
      return list
    },
    taskConfigStr() {
      const { jobType,hour,minute,second,dayOfMonths,dayOfWeeks } = this.item.taskScheduleConfig
      let str = ''
      if (jobType===2){
        str += `每周${dayOfWeeks[0]}`
      } else if (jobType===3){
        str += `每月${dayOfMonths[0]}日`
      }
      str+=` ${hour}:${minute<10?'0'+minute:minute}:${second<10?'0'+second:second}`
      return str
      // return `每${jobType===2?'周':jobType===3?'月':''}${jobType===2?dayOfWeeks[0]:jobType===3?dayOfMonths[0]:''}${hour}:${minute}:${second}`
    }
  },
  methods: {
    onFinish(taskId,scheduleId){
      this.$emit('finish',taskId,scheduleId)
    },
    isCurrentTask(subItem){
      return subItem.scheduleStatus !== 3 && !dayjs().isBefore(dayjs(subItem.endTimeStr),'D')
    }
  }
}
</script>
<style lang="less" scoped>
.sop-item{
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 12px;
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    color: #323232;
    line-height: 18px;
    padding-bottom: 12px;
  }
  .content{
    font-size: 13px;
    padding: 12px 8px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-top: 12px;
    .row{
      display: flex;
      align-items: flex-start;
      .value{
        flex: 1;
        line-height: 22px;
        word-break: break-all;
      }
    }
  }
  .footer{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    padding: 12px 0;
    .completed{
      position: relative;
      padding-left: 16px;
      &::before{
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        background: green;
        content: '';
      }
    }
  }
  .van-ellipsis{
    color: #0d171a;
    line-height: 22px;
    &.flex{
      display: flex;
      justify-content: space-between;
    }
    .title{
      color: rgba(13,23,26,0.45);
    }
  }
  .complete-btn{
    padding: 0 8px !important; 
    /deep/.van-button__text{
      font-size: 13px;
    }
  }
  .sub-item{
    font-size: 13px;
    border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    padding-bottom: 8px;
    .sub-head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #323232;
      line-height: 18px;
      padding: 4px 0px;
      .current-task{
        color: #B9921A;
      }
    }
  }
  
}
</style>